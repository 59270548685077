import { graphql, PageProps } from "gatsby";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { PostListItem } from "@/components/PostListItem";

const PostListByTagPage: React.FC<PageProps<Queries.TagPageQuery>> = ({
  data,
}) => {
  const tag = data.contentfulTag?.name;
  const title = tag + "の投稿一覧";
  const description = `Boctozの記事の中で${tag}に関係のある投稿の一覧です`;

  const seoProps: GatsbySeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      url: `https://boctoz.com/tag/${data.contentfulTag?.contentful_id}`,
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="prose pt-12 pb-8">
        <h1 className="md:text-6xl">{title}</h1>
      </div>
      <ul className="mt-8 list-none ">
        {data.allContentfulPost.nodes.map((props) => (
          <PostListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query TagPage($contentful_id: String) {
    contentfulTag(contentful_id: { eq: $contentful_id }) {
      name
      contentful_id
    }
    allContentfulPost(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: $contentful_id } } }
        }
      }
    ) {
      nodes {
        ...PostListItemProps
      }
    }
  }
`;

export default PostListByTagPage;
